<!-- Planning result for uniliver -->
<template>
  <div>
    <div
      class="container-fluid"
      style="margin-top: 71px; position: relative"
      v-show="showBlock === 'planningResult'"
    >
      <Plan ref="savePlanModal" @updatePlan="updatePlan" />
      <Toast
        v-if="toastData.show"
        :type="toastData.type"
        :message="toastData.message"
        :show="toastData.show"
        :time="10000"
        @clearToast="clearToast"
      />
      <div>
        <ul class="breadcrumb ProximaNovaRegular">
          <li>
            <router-link to="/solutions/in/platform/models">
              <span>Model</span>
            </router-link>
          </li>
          <li>
            <router-link to="/solutions/in/platform/stat">
              <span>Sale Statistics</span>
            </router-link>
          </li>
          <li>
            <router-link to="/solutions/in/platform/insights">
              <span>Future Planning</span>
            </router-link>
          </li>
          <li class="ProximaNovaBold">Planning Results</li>
        </ul>
      </div>
      <div
        class="d-flex mt-4"
        :class="[expand ? 'justify-content-end' : 'justify-content-between']"
      >
        <div class="w-100 d-flex" style="justify-content: space-between;">
          <div>
            <div
              class="d-none d-md-block card arrow-card"
              :class="[expand ? 'arrow-card-collapse' : '']"
              @click="toggleExpand"
            >
              <i v-if="!expand" class="fas fa-arrow-left arrow"></i>
              <i v-else class="fas fa-arrow-right arrow-white"></i>
            </div>
          </div>
          <div>
            <w-button
              class="mx-2 btn-effect"
              @buttonClicked="$refs.savePlanModal.open('add')"
              :buttonText="'Save Plan'"
            >
            </w-button>
            <w-button
              class="mx-2 btn-effect"
              @buttonClicked="showAgile"
              :buttonText="'Agile Planning'"
              style="width: auto;"
            >
            </w-button>
            <w-button
              class="btn-effect-outline"
              @buttonClicked="gotoScenarioPlan"
              :buttonLoader="'none'"
              :buttonText="'Scenerio Planning'"
            >
            </w-button>
          </div>
        </div>
      </div>
      <b-row>
        <b-col cols="12" md="4" :class="[expand ? 'd-none' : '']">
          <div class="card">
            <div class="card-body p-0">
              <div class="card-title">Prediction</div>
              <div class="mt-5">
                <w-tab
                  :tabName="activePredictionTab"
                  :tabs="predictionTab"
                  @activeTab="changePredictionTab($event)"
                >
                </w-tab>
              </div>
              <b-container fluid>
                <SalesGrowth
                  v-if="activePredictionTab === 'Revenue Based'"
                  :sliderValue="sliderRevenueValue"
                  :data="revenueData"
                  :bigRangeSize="true"
                  :handleColor="'#536dfe'"
                  :step="5"
                  @sliderChangeHandle="handleRevenueSlider"
                />
                <SalesGrowth
                  v-if="activePredictionTab === 'Budget Based'"
                  :sliderValue="sliderBudgetValue"
                  :data="budgetData"
                  :handleColor="'#536dfe'"
                  :bigRangeSize="true"
                  @sliderChangeHandle="handleBudgetSlider"
                />
              </b-container>
            </div>
          </div>
          <div class="card mt-5 mb-5 mb-md-0">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-12">
                  <details class="">
                    <Summary class="Additional-details"
                      >Additional Details</Summary
                    >
                    <b-container fluid>
                      <div
                        v-for="(detail, index) in additionalDetails"
                        :key="index"
                        class="row additonal-detail py-5 px-4"
                        :class="[!detail.select ? 'border-top' : 'detail-bg']"
                      >
                        <b-col cols="10">
                          <div class="details-heading">{{ detail.name }}</div>
                          <div v-if="detail.select">
                            <label>Product</label>
                            <b-form-select
                              v-model="detail.product"
                              :options="detail.dropdownList"
                            ></b-form-select>
                            <label>Date</label>
                            <div>
                              <input
                                type="date"
                                :value="detail.date"
                                class="date-input"
                              />
                            </div>
                            <label>Value in numbers</label>
                            <b-form-input
                              v-model="detail.value"
                              type="number"
                            ></b-form-input>
                          </div>
                        </b-col>
                        <b-col cols="2">
                          <input
                            :name="`checkbox-${index}`"
                            type="checkbox"
                            class="form-check-input"
                            :value="detail.select"
                            @input="
                              updateCheckbox(index, $event.target.checked)
                            "
                          />
                        </b-col>
                      </div>
                    </b-container>
                  </details>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" :md="expand ? 12 : 8">
          <div class="card mb-4">
            <div class="card-body p-0">
              <div class="card-title">Prediction</div>
              <b-container fluid class="wrapper-statistics">
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    :md="expand ? 2 : 4"
                    v-for="(data, index) in predictionData"
                    :key="index"
                    class="item"
                  >
                    <div
                      class="item-img"
                      :class="[
                        index === 1
                          ? 'blue-pastel'
                          : index === 2
                          ? 'green-pastel'
                          : index === 3
                          ? 'pink-pastel'
                          : index === 4
                          ? 'red-pastel'
                          : index === 5
                          ? 'yellow-pastel'
                          : 'blue-pastel',
                      ]"
                    >
                      <img
                        :src="require(`@/assets/Icons/${data.name}.svg`)"
                        :alt="data.name"
                        height="25px"
                      />
                    </div>

                    <div class="item-text" v-if="data.name === 'Variance'">
                      <div class="item-text__title">{{ data.name }}</div>
                      <div class="item-text__subtitle">{{ data.value }}%</div>
                    </div>
                    <div class="item-text" v-else>
                      <div class="item-text__title">{{ data.name }}</div>
                      <div class="item-text__subtitle">{{ data.value }}M</div>
                    </div>

                    <div
                      v-if="
                        (!expand && (index + 1) % 3 !== 0) ||
                          (expand && (index + 1) % 6 !== 0)
                      "
                      class="item-right-line"
                    ></div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-body p-3">
              <ColumnChart :chartData="columnChartData" />
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-body pt-2">
              <Content
                :barData="tvExpandData"
                :stripData="tvData"
                subtitle="Recommended TV Mix"
                @getDataBreakdown="getTVBreakdown"
                total=""
                :title="'Television'"
              />
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-body pt-2">
              <Digital
                :data="digitalExpandData"
                :stripData="digitalData"
                subtitle="Recommended Digital Mix"
                @getDataBreakdown="getDigitalBreakdown"
                total=""
              />
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-body pt-2">
              <Content
                :barData="printExpandData"
                :stripData="printData"
                subtitle="Recommended Print Mix"
                @getDataBreakdown="getPrintBreakdown"
                total=""
                :title="'Print'"
              />
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-body pt-2">
              <Content
                :barData="radioExpandData"
                :stripData="radioData"
                subtitle="Recommended Radio Mix"
                @getDataBreakdown="getRadioBreakdown"
                total=""
                :title="'Radio'"
              />
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-body pt-2">
              <Content
                :barData="sponsorshipExpandData"
                :stripData="sponsorshipData"
                subtitle="Recommended Sponsorship Mix"
                @getDataBreakdown="getSponsorshipBreakdown"
                total=""
                :title="'Sponsorship'"
              />
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-body pt-2">
              <Content
                :barData="contentExpandData"
                :stripData="contentData"
                subtitle="Recommended Content Mix"
                @getDataBreakdown="getContentBreakdown"
                total=""
              />
            </div>
          </div>
          <!--<div class="card mb-4">
            <div class="card-body pt-2">
              <Content
                :barData="billboardExpandData"
                :stripData="billboardData"
                subtitle="Recommended Billboard Mix"
                @getDataBreakdown="getBillboardBreakdown"
                total=""
                :title="'Billboard'"
              />
            </div>
          </div>-->
          <div class="card">
            <div class="card-body p-3">
              <LineChart :chartData="lineChartData" />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <w-agile-planning-result
      @aglieBlock="showBlock = 'planningResult'"
      v-show="showBlock === 'agile'"
      :predictionRowData="predictionRowData"
      :row1Data="row1Data"
      :row2Data="row2Data"
      :row3Data="row3Data"
      :columnChartData="agileLevelOneData"
      :digitalData="digitalAgiledata"
      :digitalDropdownList="digitalAgileDropdownList"
      :digitalTitle="digitalAgileTitle"
      :expandDigitlData="digitalAgileExpandData"
      @clickedChart="clickedDigitalChart($event)"
      @changeDigitalDropdown="changeAgileDigitalDropdown($event)"
      :contentDropdownList="contentAgileDropdownList"
      :contentData="contentAgiledata"
      :contentTitle="contentAgileTitle"
      :expandContentData="contentAgileExpandData"
      @clickedContentChart="clickedContentChart($event)"
      @changeContentDropdown="changeAgileContentDropdown($event)"
      :expandTVData="tvAgileExpandData"
      :tvDropdownList="tvAgileDropdownList"
      :tvTitle="tvAgileTitle"
      :tvData="tvAgiledata"
      @clickedTVChart="clickedTVChart($event)"
      @changeTVDropdown="changeAgileTVDropdown($event)"
      :expandPrintData="printAgileExpandData"
      :printDropdownList="printAgileDropdownList"
      :printTitle="printAgileTitle"
      :printData="printAgiledata"
      @clickedPrintChart="clickedPrintChart($event)"
      @changePrintDropdown="changeAgilePrintDropdown($event)"
      :expandRadioData="radioAgileExpandData"
      :radioDropdownList="radioAgileDropdownList"
      :radioTitle="radioAgileTitle"
      :radioData="radioAgiledata"
      @clickedRadioChart="clickedRadioChart($event)"
      @changeRadioDropdown="changeAgileRadioDropdown($event)"
      :expandSponsorshipData="sponsorshipAgileExpandData"
      :sponsorshipDropdownList="sponsorshipAgileDropdownList"
      :sponsorshipTitle="sponsorshipAgileTitle"
      :sponsorshipData="sponsorshipAgiledata"
      @clickedSponsorshipChart="clickedSponsorshipChart($event)"
      @changeSponsorshipDropdown="changeAgileSponsorshipDropdown($event)"
      :expandBillboardData="billboardAgileExpandData"
    ></w-agile-planning-result>
    <!-- <page-loader v-if="countApi < countAllApi - 1"></page-loader> -->
  </div>
</template>

<script>
// import PageLoader from "@/widgets/PageLoader.vue";
import Tab from "@/components/Solutions/Tab.vue";
import SalesGrowth from "@/components/Solutions/SalesGrowth.vue";
import Digital from "@/components/Solutions/Digital.vue";
import Content from "@/components/Solutions/Content.vue";
import LineChart from "@/components/Chart/LineChart.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import Plan from "@/components/Modal/Plan.vue";
import Toast from "@/components/Toast/Toast.vue";
import Button from "@/components/Profile/Button.vue";
import { AimoINServices } from "@/services/AimoINServices.js";
const aimoUSAServices = new AimoINServices();
import AgilePlanningResult from "./AgilePlanningResults.vue";
export default {
  name: "PlanningResults",
  components: {
    "w-tab": Tab,
    "w-button": Button,
    SalesGrowth,
    Digital,
    Content,
    LineChart,
    ColumnChart,
    Plan,
    Toast,
    "w-agile-planning-result": AgilePlanningResult,
    // PageLoader,
  },
  data() {
    return {
      countApi: 0,
      market: "",
      expand: false,
      predictionTab: [
        {
          category: "Revenue Based",
        },
        // {
        //   category: "Budget Based",
        // },
      ],
      activePredictionTab: "Revenue Based",
      sliderRevenueValue: 16,
      revenueData: [{ name: "Expected Revenue", value: 621 }],
      sliderBudgetValue: 46,
      budgetData: [{ name: "Expected Budget", value: 621 }],
      digitalExpandData: [
        {
          name: "Video Campaigns",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 10, 21),
              x2: Date.UTC(2014, 11, 2),
              y: 0,
              pointWidth: 16,
              pointPadding: 0,
              legendColor: "black",
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Engagement Campaigns",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 2),
              x2: Date.UTC(2014, 11, 10),
              y: 1,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Lead Ads",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 10),
              x2: Date.UTC(2014, 11, 23),
              y: 2,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Click to website",
          pointWidth: 20,
          color: "red",
          data: [
            {
              x: Date.UTC(2014, 11, 23),
              x2: Date.UTC(2014, 11, 30),
              y: 3,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
      ],
      tvData: [],
      printData: [],
      radioData: [],
      sponsorshipData: [],
      billboardData: [],
      digitalData: [
        {
          label: "Google Analytics",
          value: 35,
          backgroundColor: "#f77f00",
        },
        {
          label: "Facebook",
          value: 20,
          backgroundColor: "#1877f2",
        },
        {
          label: "DV360",
          value: 8,
          backgroundColor: "#148f3a",
        },
        {
          label: "Linkedin",
          value: 9,
          backgroundColor: "#0077b5",
        },
        {
          label: "Instagram",
          value: 15,
          backgroundColor: "#dd2a7b",
        },
        {
          label: "Twitter",
          value: 13,
          backgroundColor: "#08a0e9",
        },
      ],
      contentData: [
        {
          label: "Paid Media",
          value: 23,
          backgroundColor: "#85edff",
        },
        {
          label: "Owned Content",
          value: 77,
          backgroundColor: "#b5a9ff",
        },
      ],
      tvExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffdd7c",
          "#b5a9ff",
          "#ffa2a2",
          "#fdbfff",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      printExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffdd7c",
          "#b5a9ff",
          "#ffa2a2",
          "#fdbfff",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      radioExpandData: {
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffdd7c",
          "#b5a9ff",
          "#ffa2a2",
          "#fdbfff",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
      },
      sponsorshipExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffdd7c",
          "#b5a9ff",
          "#ffa2a2",
          "#fdbfff",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      billboardExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffdd7c",
          "#b5a9ff",
          "#ffa2a2",
          "#fdbfff",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      contentExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffdd7c",
          "#b5a9ff",
          "#ffa2a2",
          "#fdbfff",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      predictionData: [
        {
          name: "Previous Revenue",
          value: "568",
        },
        {
          name: "Predicted Revenue",
          value: "768",
        },
        {
          name: "Variance",
          value: "268",
        },
        {
          name: "Previous Budget",
          value: "768",
        },
        {
          name: "Predicted Budget",
          value: "568",
        },
        {
          name: "Predicted Revenue",
          value: "268",
        },
      ],
      lineChartData: {
        series: [
          {
            data: [1, 2, 3, 3, 2, 1, 4, 5, 3, 7, 9, 10],
            marker: {
              symbol: "circle",
              radius: 10,
              status: {
                hover: {
                  enabled: true,
                },
              },
            },
            type: "spline",
            name: "Actual",
          },
          {
            data: [3, 2, 1, 2, 1, 4, 1, 2, 3, 3, 9, 10],
            marker: {
              symbol: "circle",
              radius: 10,
            },
            type: "spline",
            name: "Predicted",
          },
        ],
        title: {
          text: "Media - Previous vs Predicted Budget",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {
          text: "Recommended Budget Mix",
          align: "left",
          y: 60,
          style: {
            color: "#8394b4",
            fontSize: "20px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        yAxis: {
          title: {
            text: "sales growth %",
            style: {
              color: "#8394b4",
            },
            y: 12,
          },
        },
        xAxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "August",
            "Sept",
            "October",
            "November",
            "December",
          ],
        },
        colors: ["#050505", "#18ffff"],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function() {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x)
              .options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
      },
      columnChartData: {
        data: [
          {
            name: "Previous",
            data: [75, 100],
          },
          {
            name: "Predicted",
            data: [45, 50],
          },
        ],
        title: {
          text: "Level 1 - Previous Vs Predicted Budget",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {
          text: "Budget",
          align: "left",
          y: 60,
          style: {
            color: "#8394b4",
            fontSize: "20px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        xAxis: {
          categories: ["Digital", "Content"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#18ffff", "#ffab40", "#ffdd7c"],
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 20,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      additionalDetails: [
        {
          name: "Product",
          product: "",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          date: "",
          value: "",
        },
        {
          name: "Distribution",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
        {
          name: "Consumer Promo",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
        {
          name: "Advertising",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
      ],
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      digitalThirdLevelData: [],
      contentThirdLevelData: [],
      tvThirdLevelData: [],
      printThirdLevelData: [],
      radioThirdLevelData: [],
      sponsorshipThirdLevelData: [],
      billboardThirdLevelData: [],
      levelOneData: [],
      colors: [
        "#f77f00",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
        "#08a0e9",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
      ],
      digitalAgileExpandData: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      tvAgileExpandData: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      contentAgileExpandData: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      printAgileExpandData: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      radioAgileExpandData: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      sponsorshipAgileExpandData: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      billboardAgileExpandData: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      showBlock: "planningResult",
      agileLevelOneData: {
        data: [
          {
            name: "Recommended",
            data: [75, 100],
          },
          {
            name: "Planned",
            data: [45, 50],
          },
        ],
        title: {
          text: "Level 1 - Previous Vs Predicted Budget",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {
          text: "Budget",
          align: "left",
          y: 60,
          style: {
            color: "#8394b4",
            fontSize: "20px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        xAxis: {
          categories: ["Digital", "Content"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#18ffff", "#ffab40", "#ffdd7c"],
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 20,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      contentThirdLevelAgileData: [],
      digitalThirdLevelAgileData: [],
      tvThirdLevelAgileData: [],
      printThirdLevelAgileData: [],
      radioThirdLevelAgileData: [],
      sponsorshipThirdLevelAgileData: [],
      billboardThirdLevelAgileData: [],
      budgetVariance: 0,
      predictionRowData: [],
      row1Data: [],
      row2Data: [],
      row3Data: [],
      digitalAgileDropdownList: [],
      tvAgileDropdownList: [],
      digitalAgileTitle: {},
      tvAgileTitle: {},
      digitalAgiledata: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: {
            enabled: true,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedDigitalChart({
                    id: data.point.category,
                    text: data.point.category,
                  });
                },
              },
            },
          },
        },
        color: ["#d81b60", "#1e88e5"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      contentAgileDropdownList: [],
      printAgileDropdownList: [],
      radioAgileDropdownList: [],
      sponsorshipAgileDropdownList: [],
      billboardAgileDropdownList: [],
      contentAgileTitle: {},
      printAgileTitle: {},
      radioAgileTitle: {},
      sponsorshipAgileTitle: {},
      billboardAgileTitle: {},
      tvAgiledata: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: {
            enabled: true,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedTVChart({
                    id: data.point.category,
                    text: data.point.category,
                  });
                },
              },
            },
          },
        },
        color: ["#e53935", "#00acc1"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      contentAgiledata: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: {
            enabled: true,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedContentChart({
                    id: data.point.category,
                    text: data.point.category,
                  });
                },
              },
            },
          },
        },
        color: ["#40c4ff", "#536dfe"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      printAgiledata: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: {
            enabled: true,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedPrintChart({
                    id: data.point.category,
                    text: data.point.category,
                  });
                },
              },
            },
          },
        },
        color: ["#5e35b1", "#fb8c00"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      radioAgiledata: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: {
            enabled: true,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedRadioChart({
                    id: data.point.category,
                    text: data.point.category,
                  });
                },
              },
            },
          },
        },
        color: ["#ff6e40", "#ffab40"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      sponsorshipAgiledata: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: {
            enabled: true,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedSponsorshipChart({
                    id: data.point.category,
                    text: data.point.category,
                  });
                },
              },
            },
          },
        },
        color: ["#ffd740", "#00897b"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      billboardAgiledata: {
        data: [
          {
            name: "Recommended",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Planned",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: {
            enabled: true,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedBillboardChart({
                    id: data.point.category,
                    text: data.point.category,
                  });
                },
              },
            },
          },
        },
        color: ["#40c4ff", "#ffab40"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
    };
  },
  mounted() {},
  methods: {
    changeAgileDigitalDropdown(data) {
      if (
        data === this.digitalAgileTitle ||
        data === this.digitalAgileTitle.text
      ) {
        return;
      }
      this.digitalAgileTitle = data;
      this.changeDigitalAgileData(data.text);
    },
    changeAgileTVDropdown(data) {
      if (data === this.tvAgileTitle || data === this.tvAgileTitle.text) {
        return;
      }
      this.tvAgileTitle = data;
      this.changeTVAgileData(data.text);
    },
    clickedDigitalChart(data) {
      this.digitalAgileTitle = data;
      this.changeDigitalAgileData(data.text);
    },
    clickedTVChart(data) {
      this.tvAgileTitle = data;
      this.changeTVAgileData(data.text);
    },
    changeContentAgileData(name) {
      var result = this.contentThirdLevelAgileData.find(
        (obj) => obj.title.toLowerCase() === name.toLowerCase()
      ).value;
      if (result.length !== 0) {
        this.contentAgileExpandData.data[0].data = [];
        this.contentAgileExpandData.data[1].data = [];
        this.contentAgileExpandData.xAxis.categories = [];
        for (var i = 0; i < result.length; i++) {
          this.contentAgileExpandData.data[0].data.push(result[i].previous);
          this.contentAgileExpandData.data[1].data.push(result[i].predicted);
          this.contentAgileExpandData.xAxis.categories.push(result[i].name);
        }
      }
    },
    changePrintAgileData(name) {
      var result = this.printThirdLevelAgileData.find(
        (obj) => obj.title.toLowerCase() === name.toLowerCase()
      ).value;
      if (result.length !== 0) {
        this.printAgileExpandData.data[0].data = [];
        this.printAgileExpandData.data[1].data = [];
        this.printAgileExpandData.xAxis.categories = [];
        for (var i = 0; i < result.length; i++) {
          this.printAgileExpandData.data[0].data.push(result[i].previous);
          this.printAgileExpandData.data[1].data.push(result[i].predicted);
          this.printAgileExpandData.xAxis.categories.push(result[i].name);
        }
      }
    },
    changeRadioAgileData(name) {
      var result = this.radioThirdLevelAgileData.find(
        (obj) => obj.title.toLowerCase() === name.toLowerCase()
      ).value;
      if (result.length !== 0) {
        this.radioAgileExpandData.data[0].data = [];
        this.radioAgileExpandData.data[1].data = [];
        this.radioAgileExpandData.xAxis.categories = [];
        for (var i = 0; i < result.length; i++) {
          this.radioAgileExpandData.data[0].data.push(result[i].previous);
          this.radioAgileExpandData.data[1].data.push(result[i].predicted);
          this.radioAgileExpandData.xAxis.categories.push(result[i].name);
        }
      }
    },
    changeSponsorshipAgileData(name) {
      var result = this.sponsorshipThirdLevelAgileData.find(
        (obj) => obj.title.toLowerCase() === name.toLowerCase()
      ).value;
      if (result.length !== 0) {
        this.sponsorshipAgileExpandData.data[0].data = [];
        this.sponsorshipAgileExpandData.data[1].data = [];
        this.sponsorshipAgileExpandData.xAxis.categories = [];
        for (var i = 0; i < result.length; i++) {
          this.sponsorshipAgileExpandData.data[0].data.push(result[i].previous);
          this.sponsorshipAgileExpandData.data[1].data.push(
            result[i].predicted
          );
          this.sponsorshipAgileExpandData.xAxis.categories.push(result[i].name);
        }
      }
    },
    changeBillboardAgileData(name) {
      var result = this.billboardThirdLevelAgileData.find(
        (obj) => obj.title.toLowerCase() === name.toLowerCase()
      ).value;
      if (result.length !== 0) {
        this.billboardAgileExpandData.data[0].data = [];
        this.billboardAgileExpandData.data[1].data = [];
        this.billboardAgileExpandData.xAxis.categories = [];
        for (var i = 0; i < result.length; i++) {
          this.billboardAgileExpandData.data[0].data.push(result[i].previous);
          this.billboardAgileExpandData.data[1].data.push(result[i].predicted);
          this.billboardAgileExpandData.xAxis.categories.push(result[i].name);
        }
      }
    },

    changeDigitalAgileData(name) {
      var result = this.digitalThirdLevelAgileData.find(
        (obj) => obj.title.toLowerCase() === name.toLowerCase()
      ).value;
      if (result.length !== 0) {
        this.digitalAgileExpandData.data[0].data = [];
        this.digitalAgileExpandData.data[1].data = [];
        this.digitalAgileExpandData.xAxis.categories = [];
        for (var i = 0; i < result.length; i++) {
          this.digitalAgileExpandData.data[0].data.push(result[i].previous);
          this.digitalAgileExpandData.data[1].data.push(result[i].predicted);
          this.digitalAgileExpandData.xAxis.categories.push(result[i].name);
        }
      }
    },
    changeTVAgileData(name) {
      var result = this.tvThirdLevelAgileData.find(
        (obj) => obj.title.toLowerCase() === name.toLowerCase()
      ).value;
      if (result.length !== 0) {
        this.tvAgileExpandData.data[0].data = [];
        this.tvAgileExpandData.data[1].data = [];
        this.tvAgileExpandData.xAxis.categories = [];
        for (var i = 0; i < result.length; i++) {
          this.tvAgileExpandData.data[0].data.push(result[i].previous);
          this.tvAgileExpandData.data[1].data.push(result[i].predicted);
          this.tvAgileExpandData.xAxis.categories.push(result[i].name);
        }
      }
    },
    changeAgileContentDropdown(data) {
      if (
        data === this.contentAgileTitle ||
        data === this.contentAgileTitle.text
      ) {
        return;
      }
      this.contentAgileTitle = data;
      this.changeContentAgileData(data.text);
    },
    changeAgilePrintDropdown(data) {
      if (data === this.printAgileTitle || data === this.printAgileTitle.text) {
        return;
      }
      this.printAgileTitle = data;
      this.changePrintAgileData(data.text);
    },
    changeAgileRadioDropdown(data) {
      if (data === this.radioAgileTitle || data === this.radioAgileTitle.text) {
        return;
      }
      this.radioAgileTitle = data;
      this.changeRadioAgileData(data.text);
    },
    changeAgileSponsorshipDropdown(data) {
      if (
        data === this.sponsorshipAgileTitle ||
        data === this.sponsorshipAgileTitle.text
      ) {
        return;
      }
      this.sponsorshipAgileTitle = data;
      this.changeSponsorshipAgileData(data.text);
    },
    changeAgileBillboardDropdown(data) {
      if (
        data === this.billboardAgileTitle ||
        data === this.billboardAgileTitle.text
      ) {
        return;
      }
      this.billboardAgileTitle = data;
      this.changeBillboardAgileData(data.text);
    },
    clickedContentChart(data) {
      this.contentAgileTitle = data;
      this.changeContentAgileData(data.text);
    },
    clickedPrintChart(data) {
      this.printAgileTitle = data;
      this.changePrintAgileData(data.text);
    },
    clickedRadioChart(data) {
      this.radioAgileTitle = data;
      this.changeRadioAgileData(data.text);
    },
    clickedSponsorshipChart(data) {
      this.sponsorshipAgileTitle = data;
      this.changeSponsorshipAgileData(data.text);
    },
    clickedBillboardChart(data) {
      this.billboardAgileTitle = data;
      this.changeBillboardAgileData(data.text);
    },
    showAgile() {
      this.showBlock = "agile";
    },
    handleBudgetSlider(e) {
      this.sliderBudgetValue = e;
    },
    handleRevenueSlider(e) {
      if (e > 30) {
        return;
      }
      var data = this.predictionData.find(
        (obj1) => obj1.name === "Last year Revenue"
      ).value;
      var result = (data * e) / 100 + data;
      this.predictionData.find(
        (obj) => obj.name === "Expected Revenue"
      ).value = result;
      this.sliderRevenueValue = e;
      sessionStorage.setItem("edited", "notedited");
      sessionStorage.setItem("growthValIndia", e);
      this.predictionData.pop();
      this.predictionData.pop();
      this.predictionData.pop();
      this.predictionData.pop();
      this.predictionData.push({ name: "Variance", value: e });
      this.predictionData.push({
        name: "Previous Budget",
        value: sessionStorage.getItem("previousBudget"),
      });
      this.countApi = 0;
      this.getVariance();
    },
    getContentBreakdown(data) {
      var expandData;
      if (this.contentThirdLevelData.length === 0) return;
      expandData = this.contentThirdLevelData.find(
        (obj) => obj.title === data.label
      ).value;
      this.contentExpandData.series = [];
      for (var i = 0; expandData.length; i++) {
        this.contentExpandData.series.push({
          name: expandData[i].name,
          data: [expandData[i].value],
          pointWidth: 16,
          yAxis: 1,
        });
        if (i === expandData.length - 1) return;
      }
    },
    getTVBreakdown(data) {
      var expandData;
      if (this.tvThirdLevelData.length === 0) return;
      expandData = this.tvThirdLevelData.find((obj) => obj.title === data.label)
        .value;
      this.tvExpandData.series = [];
      for (var i = 0; expandData.length; i++) {
        this.tvExpandData.series.push({
          name: expandData[i].name,
          data: [expandData[i].value],
          pointWidth: 16,
          yAxis: 1,
        });
        if (i === expandData.length - 1) return;
      }
    },
    getPrintBreakdown(data) {
      var expandData;
      if (this.printThirdLevelData.length === 0) return;
      expandData = this.printThirdLevelData.find(
        (obj) => obj.title === data.label
      ).value;
      this.printExpandData.series = [];
      for (var i = 0; expandData.length; i++) {
        this.printExpandData.series.push({
          name: expandData[i].name,
          data: [expandData[i].value],
          pointWidth: 16,
          yAxis: 1,
        });
        if (i === expandData.length - 1) return;
      }
    },
    getRadioBreakdown(data) {
      var expandData;
      if (this.radioThirdLevelData.length === 0) return;
      expandData = this.radioThirdLevelData.find(
        (obj) => obj.title === data.label
      ).value;
      this.radioExpandData.series = [];
      for (var i = 0; expandData.length; i++) {
        this.radioExpandData.series.push({
          name: expandData[i].name,
          data: [expandData[i].value],
          pointWidth: 16,
          yAxis: 1,
        });
        if (i === expandData.length - 1) return;
      }
    },
    getSponsorshipBreakdown(data) {
      var expandData;
      if (this.sponsorshipThirdLevelData.length === 0) return;
      expandData = this.sponsorshipThirdLevelData.find(
        (obj) => obj.title === data.label
      ).value;
      this.sponsorshipExpandData.series = [];
      for (var i = 0; expandData.length; i++) {
        this.sponsorshipExpandData.series.push({
          name: expandData[i].name,
          data: [expandData[i].value],
          pointWidth: 16,
          yAxis: 1,
        });
        if (i === expandData.length - 1) return;
      }
    },
    getBillboardBreakdown(data) {
      var expandData;
      if (this.billboardThirdLevelData.length === 0) return;
      expandData = this.billboardThirdLevelData.find(
        (obj) => obj.title === data.label
      ).value;
      this.billboardExpandData.series = [];
      for (var i = 0; expandData.length; i++) {
        this.billboardExpandData.series.push({
          name: expandData[i].name,
          data: [expandData[i].value],
          pointWidth: 16,
          yAxis: 1,
        });
        if (i === expandData.length - 1) return;
      }
    },
    getDigitalThirdLevelData(data) {
      aimoUSAServices
        .getPredictedThirdLevel(
          data.label.toLowerCase(),
          this.market,
          data,
          "NotEdited",
          data.value,
          true
        )
        .then((res) => {
          this.digitalThirdLevelData.push({ title: data.label, value: res });
          this.getAgileThirdLevelData(
            data.label,
            "digitalThirdLevelData",
            "digitalThirdLevelAgileData"
          );
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getContentThirdLevelData(data) {
      aimoUSAServices
        .getPredictedThirdLevel(
          data.label.toLowerCase(),
          this.market,
          data,
          "NotEdited",
          data.value,
          true
        )
        .then((res) => {
          this.contentThirdLevelData.push({ title: data.label, value: res });
          this.getAgileThirdLevelData(
            data.label,
            "contentThirdLevelData",
            "contentThirdLevelAgileData"
          );
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getTVThirdLevelData(data) {
      aimoUSAServices
        .getPredictedThirdLevel(
          data.label.toLowerCase(),
          this.market,
          data,
          "NotEdited",
          data.value,
          true,
        )
        .then((res) => {
          this.tvThirdLevelData.push({ title: data.label, value: res });
          this.getAgileThirdLevelData(
            data.label,
            "tvThirdLevelData",
            "tvThirdLevelAgileData"
          );
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getPrintThirdLevelData(data) {
      aimoUSAServices
        .getPredictedThirdLevel(
          data.label.toLowerCase(),
          this.market,
          data,
          "NotEdited",
          data.value,
          true
        )
        .then((res) => {
          this.printThirdLevelData.push({ title: data.label, value: res });
          this.getAgileThirdLevelData(
            data.label,
            "printThirdLevelData",
            "printThirdLevelAgileData"
          );
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getRadioThirdLevelData(data) {
      aimoUSAServices
        .getPredictedThirdLevel(
          data.label.toLowerCase(),
          this.market,
          data,
          "NotEdited",
          data.value,
          true,
        )
        .then((res) => {
          this.radioThirdLevelData.push({ title: data.label, value: res });
          this.getAgileThirdLevelData(
            data.label,
            "radioThirdLevelData",
            "radioThirdLevelAgileData"
          );
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getSponsorshipThirdLevelData(data) {
      aimoUSAServices
        .getPredictedThirdLevel(
          data.label.toLowerCase(),
          this.market,
          data,
          "NotEdited",
          data.value,
          true
        )
        .then((res) => {
          this.sponsorshipThirdLevelData.push({
            title: data.label,
            value: res,
          });
          this.getAgileThirdLevelData(
            data.label,
            "sponsorshipThirdLevelData",
            "sponsorshipThirdLevelAgileData"
          );
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getDigitalBreakdown(data) {
      var expandData;
      if (this.digitalThirdLevelData.length === 0) return;
      expandData = this.digitalThirdLevelData.find(
        (obj) => obj.title === data.label
      ).value;
      var count = 0;
      this.digitalExpandData = [];
      for (var i = 0; expandData.length; i++) {
        this.digitalExpandData.push({
          name: expandData[i].name,
          pointWidth: 20,
          data: [
            {
              x: count,
              x2: count + expandData[i].value,
              y: i + 1,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        });
        if (i === expandData.length - 1) return;
        count += expandData[i].value;
      }
    },
    changePredictionTab(tabName) {
      this.activePredictionTab = tabName;
    },
    toggleExpand() {
      this.expand = !this.expand;
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    },
    updateCheckbox(index, val) {
      this.additionalDetails[index].select = val;
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    updatePlan() {
      this.toastData = {
        show: true,
        type: "success",
        message: "Yay, our system has saved your plan successfully",
      };
    },
    gotoScenarioPlan() {
      sessionStorage.setItem(
        "predictedBudget",
        this.predictionData.find((obj) => obj.name === "Predicted Budget").value
      );
      this.$router.push({
        name: "AIMO-Platform-Planning-Result Scenario",
        params: {
          digitalData: this.digitalData,
          tvData: this.tvData,
          printData: this.printData,
          radioData: this.radioData,
          sponsorshipData: this.sponsorshipData,
          billboardData: this.billboardData,
          contentData: this.contentData,
          levelOneData: this.levelOneData,
          digitalThirdLevelData: this.digitalThirdLevelData,
          contentThirdLevelData: this.contentThirdLevelData,
          tvThirdLevelData: this.tvThirdLevelData,
          printThirdLevelData: this.printThirdLevelData,
          radioThirdLevelData: this.radioThirdLevelData,
          sponsorshipThirdLevelData: this.sponsorshipThirdLevelData,
          billboardThirdLevelData: this.billboardThirdLevelData,
        },
      });
    },
    getTVData() {
      aimoUSAServices
        .getPredictedData({
          fileName: "lvlRow2C2",
          growthVal: this.sliderRevenueValue.toString(),
          market: this.market,
        })
        .then((res) => {
          var color = this.getColorName("tv");
          this.tvData = [];
          for (var i = 0; i < res.length; i++) {
            var eachOpacity;
            if(i === 0){
              eachOpacity = 1
            }else{
              if(i > 10){
                eachOpacity = 0.5
              }else{
                eachOpacity = (100-((i)*10))/100
              }
            }
            this.tvData.push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: this.getColor(color, eachOpacity),
            });
            this.getTVThirdLevelData({
              label: res[i].name,
              value: res[i].value,
            });
          }
          this.getAgileSecondLevelData(
            res,
            "tv",
            "tvAgileDropdownList",
            "tvAgiledata",
            "tvAgileTitle"
          );
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getPrintData() {
      aimoUSAServices
        .getPredictedData({
          fileName: "printElobrtdChrt",
          growthVal: this.sliderRevenueValue.toString(),
          market: this.market,
        })
        .then((res) => {
          var color = this.getColorName("print");
          this.printData = [];
          for (var i = 0; i < res.length; i++) {
            var eachOpacity;
            if(i === 0){
              eachOpacity = 1
            }else{
              if(i > 10){
                eachOpacity = 0.5
              }else{
                eachOpacity = (100-((i)*10))/100
              }
            }
            this.printData.push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: this.getColor(color, eachOpacity),
            });
            this.getPrintThirdLevelData({
              label: res[i].name,
              value: res[i].value,
            });
          }
          this.getAgileSecondLevelData(
            res,
            "print",
            "printAgileDropdownList",
            "printAgiledata",
            "printAgileTitle"
          );
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getRadioData() {
      aimoUSAServices
        .getPredictedData({
          fileName: "radioElobrtdChrt",
          growthVal: this.sliderRevenueValue.toString(),
          market: this.market,
        })
        .then((res) => {
          var color = this.getColorName("radio");
          this.radioData = [];
          for (var i = 0; i < res.length; i++) {
            var eachOpacity;
            if(i === 0){
              eachOpacity = 1
            }else{
              if(i > 10){
                eachOpacity = 0.5
              }else{
                eachOpacity = (100-((i)*10))/100
              }
            }
            this.radioData.push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: this.getColor(color, eachOpacity),
            });
            this.getRadioThirdLevelData({
              label: res[i].name,
              value: res[i].value,
            });
          }
          this.getAgileSecondLevelData(
            res,
            "radio",
            "radioAgileDropdownList",
            "radioAgiledata",
            "radioAgileTitle"
          );
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getSponsorshipData() {
      aimoUSAServices
        .getPredictedData({
          fileName: "sponsorshipElobrtdChrt",
          growthVal: this.sliderRevenueValue.toString(),
          market: this.market,
        })
        .then((res) => {
          var color = this.getColorName("sponsorship");
          this.sponsorshipData = [];
          for (var i = 0; i < res.length; i++) {
            var eachOpacity;
            if(i === 0){
              eachOpacity = 1
            }else{
              if(i > 10){
                eachOpacity = 0.5
              }else{
                eachOpacity = (100-((i)*10))/100
              }
            }
            this.sponsorshipData.push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: this.getColor(color, eachOpacity),
            });
            this.getSponsorshipThirdLevelData({
              label: res[i].name,
              value: res[i].value,
            });
          }
          this.getAgileSecondLevelData(
            res,
            "sponsorship",
            "sponsorshipAgileDropdownList",
            "sponsorshipAgiledata",
            "sponsorshipAgileTitle"
          );
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getDigitalData() {
      aimoUSAServices
        .getPredictedData({
          fileName: "lvlRow3C2",
          growthVal: this.sliderRevenueValue.toString(),
          market: this.market,
        })
        .then((res) => {
          this.digitalData = [];
          var color = this.getColorName("digital");
          for (var i = 0; i < res.length; i++) {
            var eachOpacity;
            if(i === 0){
              eachOpacity = 1
            }else{
              if(i > 10){
                eachOpacity = 0.5
              }else{
                eachOpacity = (100-((i)*10))/100
              }
            }
            this.digitalData.push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: this.getColor(color, eachOpacity),
            });
            this.getDigitalThirdLevelData({
              label: res[i].name,
              value: res[i].value,
            });
          }
          this.getAgileSecondLevelData(
            res,
            "digital",
            "digitalAgileDropdownList",
            "digitalAgiledata",
            "digitalAgileTitle"
          );
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getContentData() {
      aimoUSAServices
        .getPredictedData({
          fileName: "contentChrt",
          growthVal: this.sliderRevenueValue.toString(),
          market: this.market,
        })
        .then((res) => {
          this.contentData = [];
          var color = this.getColorName("content");
          for (var i = 0; i < res.length; i++) {
            var eachOpacity;
            if(i === 0){
              eachOpacity = 1
            }else{
              if(i > 10){
                eachOpacity = 0.5
              }else{
                eachOpacity = (100-((i)*10))/100
              }
            }
            this.contentData.push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: this.getColor(color, eachOpacity),
            });
            this.getContentThirdLevelData({
              label: res[i].name,
              value: res[i].value,
            });
          }
          this.getAgileSecondLevelData(
            res,
            "content",
            "contentAgileDropdownList",
            "contentAgiledata",
            "contentAgileTitle"
          );
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getMonthData() {
      aimoUSAServices
        .getPredictedData({
          fileName: "mnthLvlRow1C2",
          growthVal: this.sliderRevenueValue.toString(),
          market: this.market,
        })
        .then((res) => {
          this.lineChartData.series[0].data = [];
          this.lineChartData.series[1].data = [];
          this.lineChartData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.lineChartData.series[0].data.push(res[i].previous);
            this.lineChartData.series[1].data.push(res[i].predicted);
            this.lineChartData.xAxis.categories.push(res[i].name);
          }
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getLevelOneData() {
      aimoUSAServices
        .getPredictedData({
          fileName: "lvlRow1C1",
          growthVal: this.sliderRevenueValue.toString(),
          market: this.market,
        })
        .then((res) => {
          this.levelOneData = res;
          this.columnChartData.data[0].data = [];
          this.columnChartData.data[1].data = [];
          this.columnChartData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.columnChartData.data[0].data.push(res[i].previous);
            this.columnChartData.data[1].data.push(res[i].predicted);
            this.columnChartData.xAxis.categories.push(res[i].name);
          }
          this.getDigitalData();
          this.getContentData();
          this.getTVData();
          this.getPrintData();
          this.getRadioData();
          this.getSponsorshipData();
          // this.getBillboardData();
          this.getLevelOneAgileData(res);
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getVariance() {
      aimoUSAServices
        .getVariance(this.sliderRevenueValue, this.market)
        .then((res) => {
          this.budgetVariance = res.variance;
          this.predictionData.push({
            name: "Predicted Budget",
            value: res.predicted,
          });
          this.predictionData.push({ name: "Variance", value: res.variance });
          this.getLevelOneData();
          this.getAgileVariance();
          this.tvThirdLevelData = [];
          this.printThirdLevelData = [];
          this.digitalThirdLevelData = [];
          this.radioThirdLevelData = [];
          this.sponsorshipThirdLevelData = [];
          this.contentThirdLevelData = [];
          this.billboardThirdLevelData = [];

          this.tvThirdLevelAgileData = [];
          this.digitalThirdLevelAgileData = [];
          this.printThirdLevelAgileData = [];
          this.radioThirdLevelAgileData = [];
          this.sponsorshipThirdLevelAgileData = [];
          this.contentThirdLevelAgileData = [];
          this.billboardThirdLevelAgileData = [];
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getVarianceAfterEdit() {
      aimoUSAServices
        .getVarianceAfterEdit(
          sessionStorage.getItem("predictedBudget"),
          this.market,
          this.sliderRevenueValue
        )
        .then((res) => {
          this.budgetVariance = res.variance;
          this.predictionData.push({
            name: "Predicted Budget",
            value: Math.round(res.predicted * 100) / 100,
          });
          this.predictionData.push({ name: "Variance", value: res.variance });
          this.columnChartData.data[0].data = [];
          this.columnChartData.data[1].data = [];
          this.columnChartData.xAxis.categories = [];
          let levelone = this.$route.params.levelOneData;

          for (var i = 0; i < levelone.length; i++) {
            this.columnChartData.data[0].data.push(levelone[i].previous);
            this.columnChartData.data[1].data.push(levelone[i].predicted);
            this.columnChartData.xAxis.categories.push(levelone[i].name);
          }
          this.levelOneData = this.$route.params.levelOneData;
          this.digitalData = this.$route.params.digitalData;
          this.tvData = this.$route.params.tvData;
          this.printData = this.$route.params.printData;
          this.radioData = this.$route.params.radioData;
          this.sponsorshipData = this.$route.params.sponsorshipData;
          this.billboardData = this.$route.params.billboardData;
          this.contentData = this.$route.params.contentData;
          this.contentThirdLevelData = this.$route.params.contentThirdLevelData;
          this.tvThirdLevelData = this.$route.params.tvThirdLevelData;
          this.printThirdLevelData = this.$route.params.printThirdLevelData;
          this.radioThirdLevelData = this.$route.params.radioThirdLevelData;
          this.sponsorshipThirdLevelData = this.$route.params.sponsorshipThirdLevelData;
          this.billboardThirdLevelData = this.$route.params.billboardThirdLevelData;
          this.digitalThirdLevelData = this.$route.params.digitalThirdLevelData;
          this.getAgileVariance();
          this.getLevelOneAgileData(this.levelOneData);
          var digitalFormatData = [];
          for (i = 0; i < this.digitalData.length; i++) {
            digitalFormatData.push({
              name: this.digitalData[i].label,
              value: this.digitalData[i].value,
            });
            if (i === this.digitalData.length - 1) {
              this.getAgileSecondLevelData(
                digitalFormatData,
                "digital",
                "digitalAgileDropdownList",
                "digitalAgiledata",
                "digitalAgileTitle"
              );
            }
          }
          var tvFormatData = [];
          for (i = 0; i < this.tvData.length; i++) {
            tvFormatData.push({
              name: this.tvData[i].label,
              value: this.tvData[i].value,
            });
            if (i === this.tvData.length - 1) {
              this.getAgileSecondLevelData(
                tvFormatData,
                "tv",
                "tvAgileDropdownList",
                "tvAgiledata",
                "tvAgileTitle"
              );
            }
          }
          var contentFormatData = [];
          for (i = 0; i < this.contentData.length; i++) {
            contentFormatData.push({
              name: this.contentData[i].label,
              value: this.contentData[i].value,
            });
            if (i === this.contentData.length - 1) {
              this.getAgileSecondLevelData(
                contentFormatData,
                "content",
                "contentAgileDropdownList",
                "contentAgiledata",
                "contentAgileTitle"
              );
            }
          }
          var printFormatData = [];
          for (i = 0; i < this.printData.length; i++) {
            printFormatData.push({
              name: this.printData[i].label,
              value: this.printData[i].value,
            });
            if (i === this.printData.length - 1) {
              this.getAgileSecondLevelData(
                printFormatData,
                "print",
                "printAgileDropdownList",
                "printAgiledata",
                "printAgileTitle"
              );
            }
          }
          var radioFormatData = [];
          for (i = 0; i < this.radioData.length; i++) {
            radioFormatData.push({
              name: this.radioData[i].label,
              value: this.radioData[i].value,
            });
            if (i === this.radioData.length - 1) {
              this.getAgileSecondLevelData(
                radioFormatData,
                "radio",
                "radioAgileDropdownList",
                "radioAgiledata",
                "radioAgileTitle"
              );
            }
          }
          var sponsorshipFormatData = [];
          for (i = 0; i < this.sponsorshipData.length; i++) {
            sponsorshipFormatData.push({
              name: this.sponsorshipData[i].label,
              value: this.sponsorshipData[i].value,
            });
            if (i === this.sponsorshipData.length - 1) {
              this.getAgileSecondLevelData(
                sponsorshipFormatData,
                "sponsorship",
                "sponsorshipAgileDropdownList",
                "sponsorshipAgiledata",
                "sponsorshipAgileTitle"
              );
            }
          }
          // var billboardFormatData = [];
          // for (i = 0; i < this.billboardData.length; i++) {
          //   billboardFormatData.push({
          //     name: this.billboardData[i].label,
          //     value: this.billboardData[i].value,
          //   });
          //   if (i === this.billboardData.length - 1) {
          //     this.getAgileSecondLevelData(
          //       billboardFormatData,
          //       "billboard",
          //       "billboardAgileDropdownList",
          //       "billboardAgiledata",
          //       "billboardAgileTitle"
          //     );
          //   }
          // }
          this.contentThirdLevelAgileData = [];
          for (i = 0; i < this.contentThirdLevelData.length; i++) {
            this.getAgileThirdLevelData(
              this.contentThirdLevelData[i].title,
              "contentThirdLevelData",
              "contentThirdLevelAgileData"
            );
          }
          this.digitalThirdLevelAgileData = [];
          for (i = 0; i < this.digitalThirdLevelData.length; i++) {
            this.getAgileThirdLevelData(
              this.digitalThirdLevelData[i].title,
              "digitalThirdLevelData",
              "digitalThirdLevelAgileData"
            );
          }
          this.tvThirdLevelAgileData = [];
          for (i = 0; i < this.tvThirdLevelData.length; i++) {
            this.getAgileThirdLevelData(
              this.tvThirdLevelData[i].title,
              "tvThirdLevelData",
              "tvThirdLevelAgileData"
            );
          }
          this.printThirdLevelAgileData = [];
          for (i = 0; i < this.printThirdLevelData.length; i++) {
            this.getAgileThirdLevelData(
              this.printThirdLevelData[i].title,
              "printThirdLevelData",
              "printThirdLevelAgileData"
            );
          }
          this.radioThirdLevelAgileData = [];
          for (i = 0; i < this.radioThirdLevelData.length; i++) {
            this.getAgileThirdLevelData(
              this.radioThirdLevelData[i].title,
              "radioThirdLevelData",
              "radioThirdLevelAgileData"
            );
          }
          this.sponsorshipThirdLevelAgileData = [];
          for (i = 0; i < this.sponsorshipThirdLevelData.length; i++) {
            this.getAgileThirdLevelData(
              this.sponsorshipThirdLevelData[i].title,
              "sponsorshipThirdLevelData",
              "sponsorshipThirdLevelAgileData"
            );
          }
          this.billboardThirdLevelAgileData = [];
          for (i = 0; i < this.billboardThirdLevelData.length; i++) {
            this.getAgileThirdLevelData(
              this.billboardThirdLevelData[i].title,
              "billboardThirdLevelData",
              "billboardThirdLevelAgileData"
            );
          }
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getAgileVariance() {
      var revenue = this.predictionData.find(
        (obj) => obj.name === "Expected Revenue"
      ).value;
      var predictedBudget = this.predictionData.find(
        (obj) => obj.name === "Predicted Budget"
      ).value;
      aimoUSAServices
        .getAgilePlan(revenue, predictedBudget, this.budgetVariance)
        .then((res) => {
          this.predictionRowData = [];
          this.row1Data = [];
          this.row2Data = [];
          this.row3Data = [];
          this.predictionRowData.push({
            label: "Annual Target",
            value: revenue + " MN USD",
          });
          this.predictionRowData.push({
            label: "Yearly Budget",
            value: predictedBudget + " MN USD",
          });
          this.predictionRowData.push({
            label: "Growth",
            value: sessionStorage.getItem("growthValIndia") + "%",
          });
          this.row1Data.push({
            label: "M1 Target",
            value: res.monthlytarget + " MN USD",
            image: "click.svg",
          });
          this.row1Data.push({
            label: "M1 Revenue",
            value: res.monthlyachievement + " MN USD",
            image: "file-paper-fill.svg",
          });
          this.row1Data.push({
            label: "Variance",
            value: res.varianceffromtarget + "%",
          });

          this.row2Data.push({
            label: "M2 Target",
            value: res.m2target + " MN USD",
            image: "click.svg",
          });
          this.row2Data.push({
            label: "M2 Forecast",
            value: res.m2forecast + " MN USD",
            image: "file-paper-fill.svg",
          });
          this.row2Data.push({
            label: "Variance",
            value: res.varianceform2 + "%",
            image: "funds-fill.svg",
          });

          this.row3Data.push({
            label: "M2 Pl. Budget",
            value: res.m2pibudget + " MN USD",
            image: "click.svg",
          });
          this.row3Data.push({
            label: "M2 Rec. Budget",
            value: res.m2recBudget + " MN USD",
            image: "file-paper-fill.svg",
          });
          this.row3Data.push({
            label: "Variance",
            value: res.m2recvariance + "%",
            image: "funds-fill.svg",
          });
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getLevelOneAgileData(data) {
      aimoUSAServices
        .getAgileChartData("levelone", this.market, data)
        .then((res) => {
          this.agileLevelOneData.data[0].data = [];
          this.agileLevelOneData.data[1].data = [];
          this.agileLevelOneData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.agileLevelOneData.data[0].data.push(res[i].previous);
            this.agileLevelOneData.data[1].data.push(res[i].predicted);
            this.agileLevelOneData.xAxis.categories.push(res[i].name);
          }
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getAgileSecondLevelData(
      data,
      key,
      dropdownVariable,
      dataVariable,
      titleVariable
    ) {
      aimoUSAServices
        .getAgileChartData(key, this.market, data)
        .then((res) => {
          this[dropdownVariable] = [];
          this[dataVariable].data[0].data = [];
          this[dataVariable].data[1].data = [];
          this[dataVariable].xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this[dataVariable].data[0].data.push(res[i].previous);
            this[dataVariable].data[1].data.push(res[i].predicted);
            this[dataVariable].xAxis.categories.push(res[i].name);
            this[dropdownVariable].push({
              text: res[i].name,
              id: res[i].name,
              iconHTML: '<i class="fab fa-' + res[i].name + '"></i>',
            });
            if (i === res.length - 1) {
              this[titleVariable] = this[dropdownVariable][0];
            }
          }
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
    getAgileThirdLevelData(name, mainArray, agileArray) {
      var data = this[mainArray].find((obj) => obj.title === name).value;
      aimoUSAServices
        .getAgileChartData(name, this.market, data)
        .then((res) => {
          this[agileArray].push({ title: name, value: res });
          this.countApi++;
        })
        .catch(() => {
          this.countApi++;
        });
    },
  },
  created() {
    var data = JSON.parse(sessionStorage.getItem("expectedResult"));
    this.predictionData = [];
    for (var i = 0; i < data.length; i++) {
      this.predictionData.push(data[i]);
    }
    this.revenueData = [data.find((x) => x.name === "Expected Revenue")];
    this.predictionData.push({
      name: "Variance",
      value: sessionStorage.getItem("growthValIndia"),
    });
    this.predictionData.push({
      name: "Previous Budget",
      value: sessionStorage.getItem("previousBudget"),
    });
    this.budgetData = [data[1]];
    this.sliderRevenueValue = parseInt(sessionStorage.getItem("growthValIndia"));
    this.market = JSON.parse(sessionStorage.getItem("solutionsKpiMarket")).id;
    this.getMonthData();
    if (
      sessionStorage.getItem("edited") === "edited" &&
      this.$route.params.levelOneData !== undefined
    ) {
      this.getVarianceAfterEdit();
    } else {
      this.getVariance();
    }
  },
  computed: {
    getColorName() {
      return (param) => {
        var color = "";
        if (param === "content") {
          color = "rgb(251,140,0)";
        } else if (param === "tv") {
          color = "rgb(216,27,96)";
        } else if (param === "print") {
          color = "rgb(94,53,177)";
        } else if (param === "radio") {
          color = "rgb(167,19,19)";
        } else if (param === "sponsorship") {
          color = "rgb(30,136,229)";
        } else if (param === "billboardlevelone") {
          color = "rgb(0,172,193)";
        } else if (param === "digital") {
          color = "rgb(106,27,154)";
        } else {
          color = "rgb(247,127,0)";
        }
        return color;
      };
    },
    getColor() {
      return (color, opacity) => {
        var x = color.split(")");
        return x[0] + "," + opacity + ")";
      };
    },
    countAllApi() {
      var total = 0;
      total += 9;
      total += this.digitalThirdLevelData.length;
      total += this.tvThirdLevelData.length;
      total += this.contentThirdLevelData.length;
      total += this.printThirdLevelData.length;
      total += this.radioThirdLevelData.length;
      total += this.sponsorshipThirdLevelData.length;
      if (
        sessionStorage.getItem("edited") === "edited" &&
        this.$route.params.levelOneData !== undefined
      ) {
        return total - 1;
      } else {
        return total * 2 - 1;
      }
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 0 8px 0 #c9ced5;
}
.card-title {
  margin: 24px 0px 0px 24px;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}
.card-body {
  padding: 24px;
}

.arrow-card {
  width: 74px;
  height: 74px;
  padding: 25px;
  box-shadow: 0 0 8px 0 #c9ced5;
}

.arrow-card-collapse {
  background-color: black;
  left: 0;
  margin-top: 0px;
}

.arrow {
  font-size: 18px;
}

.arrow-white {
  color: #fff;
  font-size: 18px;
}

.btn-group {
  margin-bottom: 31px;
}

.btn-effect-outline {
  padding: 6px;
  border-radius: 5px;
  border: solid 2px #050505;
  font-family: ProximaNovaBold;
  font-size: medium;
  color: #222a37 !important;
  background-color: transparent !important;
}
.btn-effect-outline:hover {
  color: #fff !important;
}
.wrapper-statistics {
  padding: 40px 24px;
}

.item {
  display: flex;
  margin: 20px 0px;
  align-items: center;
}

/* .item:not(:nth-child(3n)):before {
  content: "";
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
  top: 8px;
} */

.item-right-line {
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
}

.item-img {
  background: #67e8ff;
  border-radius: 50%;
  padding: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.item-text__title {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8394b4;
  padding-bottom: 1rem;
}

.item-text__subtitle {
  font-family: ProximaNovaBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
}

.blue-pastel {
  background: #67e8ff;
}

.green-pastel {
  background: #81f89e;
}

.pink-pastel {
  background: #faacfe;
}

.red-pastel {
  background: #ffa2a2;
}

.yellow-pastel {
  background: #ffdd7c;
}

.Additional-details {
  width: 259px;
  margin: 1px 986px 2px 0;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
  padding: 28px 24px;
}

.details-heading {
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}

.form-check-input {
  height: 24px;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: #222a37;
  border-color: #222a37;
}
label {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
  margin-top: 37px;
}
input[type="number"] {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  background-color: #eff5ff;
}

input[type="date"] {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  width: 100%;
  background-color: #eff5ff;
}
select {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  padding-left: 0;
  background-color: #eff5ff;
}
option {
  color: #222a37;
  font-family: ProximaNovaRegular;
  font-size: 16px;
}
select:focus {
  border: none;
  border-bottom: 1px solid black;
  box-shadow: none;
  outline: none;
}
.detail-bg {
  background-color: #eff5ff;
}
</style>
<style>
.rs-handle.rs-move {
  border: 11px solid #fff !important;
}
</style>
